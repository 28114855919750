<template>
  <div class="settings">
    <div class="container mx-auto max-w-sm">
      <div class="flex flex-col pb-8 items-center">
        <h1 class="mt-3 font-semibold mb-8">AYARLAR</h1>
        <div class="player-avatar mb-6">
          <div class="player-avatar__canvas">
            <img :src="player.avatarUrl" :alt="player.alias">
          </div>

          <button class="btn btn--rounded btn--xs btn--primary" @click="onClickAvatarButton">
            <img src="@/assets/icon-image.svg" alt="">
          </button>
          <modal
              name="avatar-modal"
              classes="modal"
              :minWidth="200"
              :minHeight="400"
              :maxWidth="480"
              :scrollable="true"
              :adaptive="true"
              :reset="true"
              width="90%"
              height="auto">

            <div class="flex justify-end relative">
              <button @click="$modal.hide('avatar-modal')" class="absolute right-0 mr-4 mt-4">
                <img src="@/assets/times-black.svg" alt="">
              </button>
            </div>

            <div class="flex flex-col h-full">
              <h1 class="font-semibold text-center py-4 flex-shrink-0">AVATARINI SEÇ</h1>
              <div class="flex flex-col items-center overflow-scroll">
                <div class="avatar-list my-4 grid grid-cols-2 gap-4 w-full">
                  <div class="avatar-list__item justify-self-center">
                    <button>
                      <label for="file" style="cursor: pointer">
                        <img src="@/assets/add-user.svg" v-if="!imageAlias" style="color: white; width: 50%; margin: auto"
                             alt="">
                        <img :src="imageAlias" v-if="imageAlias" alt="">
                        <span>{{ imageAlias }}</span>
                        <input type="file" id="file" style="display: none" @change="uploadImage($event)" name="image">
                      </label>
                    </button>
                  </div>
                  <div class="avatar-list__item justify-self-center" v-for="(avatar,i) in avatars" :key="`avatar-${i}`">
                    <button><img :src="avatar.path" alt="" @click="onClickAvatarSelectButton(avatar)"></button>
                  </div>
                </div>
              </div>
            </div>
          </modal>
        </div>
        <div class="player-alias">
          {{ player.alias }}

          <button class="player-alias__button" @click="onClickAliasButton"><img src="@/assets/icon-edit.svg" alt=""></button>

          <modal
              name="alias-modal"
              classes="modal"
              :minWidth="200"
              :minHeight="400"
              :maxWidth="480"
              :scrollable="true"
              :reset="true"
              width="90%"
              @opened="onOpenedAliasModal"
              @closed="onClosedAliasModal"
              height="auto">

            <div class="flex justify-end relative">
              <button @click="$modal.hide('alias-modal')" class="absolute right-0 mr-4 mt-4">
                <img src="@/assets/times-black.svg" alt="">
              </button>
            </div>
            <h1 class="font-semibold text-center py-4 flex-shrink-0">İSMİNİ DEĞİŞTİR</h1>
            <form class="flex flex-col items-center mb-8" @submit.prevent="onClickSaveAliasButton">
              <input ref="alias" type="text" class="form-input" v-model="alias" maxlength="16">
            </form>
            <div class="flex justify-center items-center mb-4">
              <button class="btn btn--sm btn--secondary" @click="$modal.hide('alias-modal')">KAPAT</button>
              <button :disabled="alias == ''" :class="{'btn--disabled': alias == ''}" class="btn btn--sm btn--primary"
                      @click="onClickSaveAliasButton">KAYDET
              </button>
            </div>
          </modal>
        </div>
        <div class="player-email" v-if="!player.isGuest">
          <div v-if="player.email">{{player.email}}</div>
          <button type="button" class="underline my-1" @click="verifyAccount()" v-if="!player.isVerification">Hesabını doğrula</button>
          <p class="mt-2 mb-4 bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900" v-else>Hesabın doğrulandı.</p>
        </div>
        <div class="player-email" v-if="!player.isGuest">
          <button type="button" class="underline my-1" @click="onClickChangePassword()">Şifre Değiştir</button>
          <modal
              name="password-modal"
              classes="modal"
              :minWidth="200"
              :minHeight="400"
              :maxWidth="480"
              :scrollable="true"
              :reset="true"
              width="90%"
              height="auto">

            <div class="flex justify-end relative">
              <button @click="$modal.hide('password-modal')" class="absolute right-0 mr-4 mt-4">
                <img src="@/assets/times-black.svg" alt="">
              </button>
            </div>

            <div class="flex flex-col h-full">
              <h1 class="font-semibold text-center py-4 flex-shrink-0">ŞİFRE DEĞİŞTİR</h1>
              <div class="flex flex-col items-center px-4">
                <p class="mb-4 text-center text-danger" v-html="this.errorMsg"></p>
                <form class="flex flex-col items-center w-full" @submit.prevent="registerAction">
                  <validation-observer class="flex flex-col" style="width:80%" ref="form">
                    <validation-provider class="mb-4" tag="div" name="Eski Şifre" rules="required" v-slot="{errors}">
                      <input type="password" :disabled="loading" v-model="loginData.oldPassword" required name="oldpassword" class="form-special large" placeholder="Eski Parola">
                      <div class="error-label">{{ errors[0] }}</div>
                    </validation-provider>
                    <validation-provider class="mb-4" tag="div" name="Yeni Şifre" rules="required" v-slot="{errors}">
                      <input type="password" :disabled="loading" v-model="loginData.password" required name="password" class="form-special" placeholder="Yeni Parola">
                      <div class="error-label">{{ errors[0] }}</div>
                    </validation-provider>
                    <validation-provider tag="div" name="Yeni Şifre Tekrar" rules="required" v-slot="{errors}">
                      <input type="password" :disabled="loading" v-model="loginData.passwordReType" required name="repassword" class="form-special" placeholder="Yeni Parola Tekrar">
                      <div class="error-label">{{ errors[0] }}</div>
                    </validation-provider>
                    <button type="submit" class="btn btn--primary block mt-5 mb-5 mx-auto" v-bind:class="{'ld-over-inverse running' : this.loading == true}" @click.prevent="changePasswordAction">
                      Kaydet
                      <div class="ld ld-ring ld-spin" v-bind:class="{'running' : this.loading == true}" v-if="loading"></div>
                    </button>
                  </validation-observer>
                </form>
              </div>
            </div>
          </modal>
        </div>
        <div class="my-6 flex items-center">
          <div class="flex flex-col items-center mr-4">
            <router-link class="btn-circle mx-2" :to="{name: 'leaderboard', query: this.$route.query}">
              <img :src="require('@/assets/icon-league.svg')" alt="">
            </router-link>
            <span class="font-semibold text-xs mt-3 text-center">SKOR TABLOSU</span>
          </div>
          <div class="flex flex-col items-center mr-4">
            <button class="btn-circle mx-2" @click="onClickMyRewards('gameRewards')">
              <img :src="require('@/assets/icon-giftbox.svg')" alt="">
            </button>
            <span class="font-semibold text-xs mt-3 text-center">LİG ÖDÜLLERİM</span>
          </div>
          <!--div class="flex flex-col items-center">
            <button class="btn-circle btn-circle--primary mx-2" @click="onClickMyRewards('couponRewards')">
              <img :src="require('@/assets/icon-coupon.svg')" alt="">
            </button>
            <span class="font-semibold text-xs mt-3 text-center">HEDİYE ÇEKLERİM</span>
          </div-->
        </div>
        <modal
            name="my-rewards-modal"
            classes="modal modal--white"
            :minWidth="200"
            :minHeight="400"
            :maxWidth="480"
            :scrollable="true"
            :adaptive="true"
            :reset="true"
            @before-open="onBeforeOpenRewardsModal"
            width="90%"
            height="auto">
          <div class="flex justify-end relative">
            <button @click="$modal.hide('my-rewards-modal')" class="absolute right-0 mr-4 mt-4">
              <img src="@/assets/times-black.svg" alt="">
            </button>
          </div>
          <div class="flex flex-col items-start px-4 mb-8 h-full">
            <h1 class="my-4 font-semibold">ÖDÜLLERİM</h1>
            <div class="modal-tab">
              <div class="modal-tab__item" :class="{'active': rewardTab == 'gameRewards'}" @click="onClickRewardTab('gameRewards')">
                OYUN İÇİ ÖDÜLLER
              </div>
              <!--div class="modal-tab__item modal-tab__item--important" :class="{'active': rewardTab == 'couponRewards'}" @click="onClickRewardTab('couponRewards')">
                HEDİYE ÇEKLERİM
              </div-->
            </div>
            <div class="modal-tab__content mt-6" v-if="rewardTab == 'gameRewards'">
              <template v-if="rewardsLoading">
                <loading :active.sync="this.rewardsLoading" :is-full-page="false" :color="`#FFB74F`" :width="32" :height="32"></loading>
                <div class="text-center">Yükleniyor...</div>
              </template>
              <template v-else>
                <table class="table-auto player-rewards__table w-full" v-if="rewards.length">
                  <colgroup>
                    <col style="width: 10%">
                    <col style="width: 15%">
                    <col style="width: 75%">
                  </colgroup>
                  <thead>
                  <tr>
                    <th>Hafta</th>
                    <th>Sıra</th>
                    <th>Ödül</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(reward, i) in rewards" :key="`reward-${i}`">
                    <td>
                      <span>{{reward.powerups.rewardedAt}}</span>
                    </td>
                    <td align="center">
                      <div class="ribbons__item ribbons__item--bronze ribbons__item--sml pt-4" v-if="reward.standing > 0 && reward.standing <= 10">
                        <span class="text-xl font-semibold">{{reward.standing }}.</span>
                      </div>
                      <div class="text-xl font-semibold" v-else>{{reward.standing }}.</div>
                    </td>
                    <td>
                      <reward-box :instructions="reward.powerups.reward" :isTooltip="false"></reward-box>
                      <!-- <reward-box :instructions="rewardTest" :isTooltip="false"></reward-box> -->
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div v-else class="border-2 border-gray-400 p-2 rounded-xl border-dashed	w-full">Henüz kazandığınız bir ödül bulunmuyor</div>
              </template>
            </div>
            <div class="modal-tab__content px-1" v-if="rewardTab == 'couponRewards'">
              <div class="flex justify-end my-2 cursor-pointer" @click="onClickShowCouponHelpModal"><img class="mr-2" style="height:32px" src="@/assets/icon-game-help.svg" alt=""></div>
              <template v-if="rewardsLoading">
                <loading :active.sync="this.rewardsLoading" :is-full-page="false" :color="`#FFB74F`" :width="32" :height="32"></loading>
                <div class="text-center">Yükleniyor...</div>
              </template>
              <template v-else>
                <template v-if="couponss.length">
                  <div class="flex flex-col items-center relative mb-12" v-for="(coupon, index) in coupons" :key="`coupon-${index}`">
                    <div class="coupon-item mt-10">
                      <h2>
                        <div class="absolute ribbons__item ribbons__item--bronze ribbons__item--sml pt-4">
                          <span class="text-2xl font-semibold">{{coupon.giftCard.standing}}</span>
                        </div>
                        <div class="flex flex-col">
                          LİG ÖDÜLÜ
                          <span class="text-xs text-gray-500">{{coupon.giftCard.createdAt}}</span>
                        </div>
                      </h2>
                      <div class="flex justify-between items-center w-full">
                        <div class="coupon-item__left flex flex-col justify-center items-center w-1/3 px-4">
                          <img :src="`https://cdn.adam-asmaca.com${coupon.giftCard.image}`" style="max-width: 100px" alt="">
                          <span>{{coupon.giftCard.company}}</span>
                        </div>
                        <div v-if="openedCoupons.includes(coupon.giftCard.id)" class="coupon-item__right flex items-center justify-center relative flex-col w-2/3">
                          <h3 class="text-4xl mb-2">{{coupon.giftCard.rewardAmount}}<span class="text-xl ml-1 font-normal">TL</span></h3>
                          <div class="flex items-center"><span class="text-sm mr-1 whitespace-nowrap">Kod :</span><div class="coupon-code">{{coupon.giftCard.code}}</div></div>
                          <div class="mt-2 text-xs"><span class="mr-1">Geçerlilik Tarihi :</span><span class="font-semibold">{{coupon.giftCard.expireAt}}</span></div>
                        </div>
                        <div v-else class="cursor-pointer underline mr-6" @click="onClickOpenCoupon(coupon.giftCard.id)">
                          Görmek İçin Tıklayın
                        </div>
                      </div>
                    </div>
                    <div class="mt-2 mb-4">
                      <button class="btn-facebook-share relative mb-1" @click="onClickFacebookShare(coupon.giftCard)">
                        <img class="img-svg" src="@/assets/facebook.svg" alt="">
                        Paylaş
                      </button>
                      <div class="flex items-center mb-4" :id="`gCard${coupon.giftCard.id}`" v-if="coupon.giftCard.fbShared == 0"><b class="ml-2 flex items-center">50 <img class="ml-1 mr-2" src="@/assets/coin-small.svg"> KAZAN!</b></div>
                    </div>
                  </div>
                </template>
                <div v-else class="border-2 border-gray-400 p-2 rounded-xl border-dashed	w-full">1 Ocak 2023'te yürürlüğe girecek olan yeni e-ticaret kanunu uyum için Hediye Çeki ödüllerimiz 12 Aralık tarihinden itibaren kaldırılmıştır.</div>
              </template>
            </div>
          </div>
        </modal>
        <coupon-help-modal></coupon-help-modal>
        <div class="player-settings">
          <div class="player-settings__row mt-6 mb-0">
            <label>Bildirimler :</label>
            <VueToggles
                :value="isNotificationEnabled"
                name="notfy"
                checked-text="AÇIK"
                unchecked-text="KAPALI"
                height="36"
                width="100"
                checkedBg="#65D29F"
                uncheckedBg="#C4C4C4"
                checkedColor="#000000"
                uncheckedColor="#000000"
                @click="onChangeNotificationSetting" />
          </div>

          <div class="player-settings__row mt-6 mb-0">
            <label>Ses :</label>
            <VueToggles
                :value="isSoundEffectsEnabled"
                name="sound"
                checked-text="AÇIK"
                unchecked-text="KAPALI"
                height="36"
                width="100"
                checkedBg="#65D29F"
                uncheckedBg="#C4C4C4"
                checkedColor="#000000"
                uncheckedColor="#000000"
                @click="onChangeEffectSetting" />
          </div>

          <div class="player-settings__row mt-6 mb-0">
            <label>Ödeme Yöntemleri :</label>
            <div class="cursor-pointer underline mr-6" @click="onClickUpdatePaymentMethodButton">
              Düzenle
            </div>
          </div>
          <modal
              name="payment-methods-modal"
              classes="modal"
              :minWidth="200"
              :minHeight="400"
              :maxWidth="480"
              :scrollable="true"
              :adaptive="true"
              :reset="true"
              width="90%"
              height="auto">

            <div class="flex justify-end relative">
              <button @click="$modal.hide('payment-methods-modal')" class="absolute right-0 mr-4 mt-4">
                <img src="@/assets/times-black.svg" alt="">
              </button>
            </div>

            <h2 class="text-center my-4">Kayıtlı Kredi Kartları</h2>
            <div class="mx-auto px-4">
              <template v-if="paymentMethods && paymentMethods.length > 0">
                <div class="mb-4 border border-gray-400 rounded-xl p-4" v-for="(paymentMethod, index) in paymentMethods" :key="`payment-methods-${index}`">
                  <div class="flex items-center">
                    <div class="mr-4">
                      <img style="height: 48px" :src="require(`@/assets/brand-logos/${paymentMethod.card.brand}.svg`)" :alt="paymentMethod.card.brand">
                    </div>
                    <div class="flex flex-col">
                      <div class="text-lg font-bold	">**** **** **** {{ paymentMethod.card.lastFour }}</div>
                      <div class="text-sm text-gray-600">Son kullanma tarihi : {{ paymentMethod.card.expMonth }}/{{ paymentMethod.card.expYear }}</div>
                    </div>
                    <div class="ml-auto">
                      <div class="cursor-pointer underline text-lg" @click="onClickDeletePaymentMethodButton(paymentMethod.id)">Sil</div>
                    </div>
                  </div>
                </div>
              </template>
              <p v-else class="mb-4 border border-dashed border-gray-400 rounded-xl p-4 text-lg text-center">Kayıtlı kredi kartınız bulunmamaktadır.</p>
            </div>
          </modal>
        </div>
        <div class="setting-card top-horizontal-divider mt-4">
          <h3 class="my-4">SAHİP OLDUĞUN GÜÇLER</h3>
          <div class="w-full flex items-end justify-evenly">
            <div class="flex flex-col flex-1 items-center">
              <span class="text-xs text-center text-gray-700 font-normal mb-1">GERİ SARMA</span>
              <router-link :to="{name: 'store'}" class="btn btn--xs btn--gray self-center"><img
                  src="@/assets/icon-relive.svg" alt=""></router-link>
              <div class="flex flex-col text-center">
                <span class="font-semibold text-xl">{{ rewindCount }}</span>
              </div>
            </div>
            <div class="flex flex-col flex-1 items-center">
              <span class="text-xs text-center text-gray-700 font-normal mb-1">HARF AÇMA</span>
              <router-link :to="{name: 'store'}" class="btn btn--xs btn--gray self-center"><img
                  src="@/assets/block-button.svg" alt=""></router-link>
              <div class="flex flex-col text-center">
                <span class="font-semibold text-xl">{{ clueCount }}</span>
              </div>
            </div>

            <div class="flex flex-col flex-1 items-center">
              <span class="text-xs text-center text-gray-700 font-normal mb-1">PAS</span>
              <router-link :to="{name: 'store'}" class="btn btn--xs btn--gray self-center"><img
                  src="@/assets/skip-button.svg" alt=""></router-link>
              <div class="flex flex-col text-center">
                <span class="font-semibold text-xl">{{ changeCount }}</span>
              </div>
            </div>

            <div class="flex flex-col flex-1 items-center">
              <span class="text-xs text-center text-gray-700 font-normal mb-1">İPUCU</span>
              <router-link :to="{name: 'store'}" class="btn btn--xs btn--gray self-center"><img
                  src="@/assets/icon-clue.svg" alt=""></router-link>
              <div class="flex flex-col text-center">
                <span class="font-semibold text-xl">{{ hintCount }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="setting-card top-horizontal-divider mt-4 pt-8">
          <button class="btn btn--primary btn--sm button-login mb-5" @click.prevent="onClickInviteFriend">
            <div class="bText">
              <div>ARKADAŞINI DAVET ET</div>
            </div>
            <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 50</div>
          </button>
        </div>
        <div class="setting-card top-horizontal-divider mt-4 pt-8">
          <login-box></login-box>
        </div>
        <div class="px-4 py-2 my-2 flex justify-center w-full" style="bottom: 60px; z-index: 100">
          <button class="btn btn--primary btn--sm mr-8 ml-8" style="min-width: 200px" @click.prevent="onClickCreateGame">OYNA ÖDÜL KAZAN</button>
        </div>
        <modal
            name="invite-friend-modal"
            classes="modal"
            :minWidth="200"
            :minHeight="400"
            :maxWidth="480"
            :scrollable="true"
            :adaptive="true"
            :reset="true"
            width="90%"
            height="auto">

          <div class="flex justify-end relative">
            <button @click="$modal.hide('invite-friend-modal')" class="absolute right-0 mr-4 mt-4">
              <img src="@/assets/times-black.svg" alt="">
            </button>
          </div>

          <div class="flex flex-col px-6">
            <div class="flex flex-col items-center mb-4">
              <h1 class="font-semibold text-center mt-4 flex-shrink-0">ARKADAŞ DAVET</h1>
              <b class=" mx-atuo flex items-center">50 <img class="ml-1 mr-2" src="@/assets/coin-small.svg"> KAZAN!</b>
            </div>
            <a class="flex items-center border-t border-x-0 p-4 border-gray-400 hover:bg-gray-400 hover:bg-opacity-25 transition-colors" target="_blank" :href="`https://api.whatsapp.com/send?text=${encodeURI(shareLink)}`">
                <span class="btn btn--xs btn--gray mr-4">
                  <img src="@/assets/icon-whatsapp.png" alt="Whatsapp" style="height:32px">
                </span>
              <div class="flex flex-col justify-center">
                <h3>Whatsapp</h3>
                <p class="text-sm">Arkadaşlarını Whatsapp üzerinden davet et</p>
              </div>
            </a>
            <a class="flex items-center border-t border-x-0 p-4 border-gray-400 hover:bg-gray-400 hover:bg-opacity-25 transition-colors" target="_blank" :href="`mailto:?body=${encodeURI(shareLink)}`">
                <span class="btn btn--xs btn--gray mr-4">
                  <img src="@/assets/icon-mail.png" alt="Whatsapp" style="height:32px">
                </span>
              <div class="flex flex-col justify-center">
                <h3>Email</h3>
                <p class="text-sm">Arkadaşlarını email üzerinden davet et</p>
              </div>
            </a>
            <div class="flex items-center border-t border-x-0 p-4 border-gray-400">
                <span class="btn btn--xs btn--gray mr-4">
                  <img src="@/assets/icon-link.png" alt="Whatsapp" style="height:32px; min-width:32px">
                </span>
              <div class="flex flex-col justify-center">
                <h3>Link ile davet et</h3>
                <div  @click="copyURL(shareLink)" class="cursor-pointer text-center mt-1 border-dashed border-2 border-gray-400 p-2 bg-gray-400 bg-opacity-25 text-xs font-bold rounded-full">
                  {{shareLink}}
                </div>
                <div class="text-xs text-center">
                  *
                  <span v-if="isLinkCopied">Kopyalandı!</span>
                  <span v-else>Kopyalamak için linke tıklayınız</span>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
      <modal
          name="reward-history-modal"
          classes="modal"
          :minWidth="200"
          :minHeight="400"
          :maxWidth="480"
          :scrollable="true"
          :adaptive="true"
          :reset="true"
          width="90%"
          height="auto">
        <div :style="{'background-image': 'url(' + rewardHistory.img + ')'}">
          <div style="float: left">{{ rewardHistory.title }}</div>
          <div style="float: right">{{ rewardHistory.price }} TL</div>
          <div style="float: left;margin-top:10px">{{ new Date(rewardHistory.expire * 1000).toLocaleDateString("tr-tr") }} tarihine kadar geçerlidir</div>
          <div style="float: left;margin-top:10px">{{ rewardHistory.desc }}</div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import LoginBox from '@/views/components/LoginBox'
import RewardBox from '@/views/components/RewardBox'
import Feed from "@/mixins/Feed"
import CouponHelpModal from '@/views/components/CouponHelpModal';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {mapState, mapActions, mapGetters} from 'vuex'

export default {
  components: {LoginBox, ValidationProvider, ValidationObserver, RewardBox, CouponHelpModal},
  mixins: [Feed],
  data() {
    return {
      loginData: {
        id: null,
        oldPassword: null,
        password: null,
        passwordReType: null
      },
      rewardHistory: [],
      rewardTab: "gameRewards",
      rewardsLoading: false,
      loading: false,
      rewards: [],
      coupons: [],
      couponss: [],
      alias: null,
      image: null,
      imageAlias: null,
      errorMsg: null,
      isNotificationEnabled: true,
      isSoundEffectsEnabled: true,
      paymentMethods: [],
      shareLink: null,
      isLinkCopied: false,
      openedCoupons: []
    }
  },
  computed: {
    ...mapState(["player", "avatars"]),
    rewindCount() {
      return this.player.powerups.rewind > 0 ? `x ${this.player.powerups.rewind}` : 0
    },
    clueCount() {
      return this.player.powerups.open > 0 ? `x ${this.player.powerups.open}` : 0
    },
    changeCount() {
      return this.player.powerups.change > 0 ? `x ${this.player.powerups.change}` : 0
    },
    hintCount() {
      return this.player.powerups.hint > 0 ? `x ${this.player.powerups.hint}` : 0
    }
  },
  watch: {
    "player.alias": function () {
      this.alias = this.player.alias;
    },
    "image": {
      handler: function (newValue) {
        this.playerFileUpload({
          id: this.player.id,
          data: newValue
        }).then((response) => {
          this.$toast.success(response.message);
          this.player.avatarUrl = response.result.set.value;
          this.$modal.hide('avatar-modal')
        }).catch((error) => {
          this.$toast.error(error.message);
        })
      },
      deep: true
    }
  },
  created() {
    this.alias = this.player.alias;
    this.getAvatars().then(() =>{
      this.isNotificationEnabled = this.player.appSettings.notificationEnabled
      this.isSoundEffectsEnabled = this.player.appSettings.soundEffectsEnabled
      this.shareLink = 'https://www.adam-asmaca.com/?scode=' + this.player.shareHash
    })
  },
  methods: {
    ...mapActions(["updatePlayerAlias", "updatePlayerAvatar", "getAvatars", "playerFileUpload", "login", "register", "changePassword", "createGame", "checkOpenGame", "enablePlayerNotification", "disablePlayerNotification", "getRewardPowerups", "getRewardCoupons", "enableSoundEffects", "disableSoundEffects", "getPaymentMethods", "removePaymentMethod", "sendRewardForSocialMedia"]),
    ...mapGetters(["checkTicket", "hasImmortality"]),
    onClickFacebookShare(payload) {
      window.FB.ui({
        method: 'feed',
        link: `https://www.adam-asmaca.com/?ref_id=cp${payload.id}`,
        hashtag: "#adamasmaca",
        quote: `${payload.title} kelimesini kazandım. Sende kazanmak istermisin?`
      },(response) => {
        if(!response.error_code){
          this.sendRewardForSocialMedia({action:"share-coupon",network:"facebook",refId:payload.id}).then(() => {
            document.getElementById("gCard" + payload.id).style.display = "none"
            this.feedAllEvent('share_coupon', null, 'facebook', 'facebook', payload.id)
          })
        }
      });
    },
    onBeforeOpenRewardsModal(evt) {
      this.rewardTab = evt.params?.tab;
      this.getRewards();
    },
    onClickMyRewards(tab) {
      this.$modal.show("my-rewards-modal", {tab});
    },
    onClickShowCouponHelpModal() {
      this.$modal.show("coupon-help-modal");
    },
    onClickShowReward(img, title, price, expire, desc){
      this.rewardHistory.img = img
      this.rewardHistory.title = title
      this.rewardHistory.price = price
      this.rewardHistory.expire = expire
      this.rewardHistory.desc = desc

      this.$modal.show("reward-history-modal");
    },
    onClickRewardTab(tab) {
      this.rewardTab = tab;
    },
    onClickChangePassword(){
      this.loginData.id = this.player.id
      this.$modal.show("password-modal");
    },
    changePasswordAction(){
      if(this.loginData.password != this.loginData.passwordReType){
        this.$toast.error("Yeni girmiş olduğunuz şifre tekrarıyla uyuşmamaktadır")
        return
      }
      this.errorMsg = "";
      this.$refs.form.validate().then((isValid) => {
        if(!isValid) return;
        this.loading = true;
        this.changePassword(this.loginData).then(() => {
          this.$toast.success("Şifreniz değiştirilmiştir");
          this.$modal.hide("password-modal");
          this.loading = false;
        }).catch(error => {
          this.loading = false;
          this.$toast.error(error.response.data.message);
        });
      })

    },
    onClickCreateGame() {
      this.checkOpenGame().then(() => {
        if (this.checkTicket()) {
          this.createGame({gameType: '1'}).then(() => {
            this.$emit('onCreate')
            this.$router.push({name: "game", params: {id: 'lig-oyunu'}, query: this.$route.query})
          })
        } else {
          this.$modal.show("promo-modal", {promoType: "ticket"});
        }
      });
    },
    uploadImage(event) {
      this.createBase64Image(event.target.files[0]);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.image = e.target.result
        this.imageAlias = e.target.result
      }
      reader.readAsDataURL(fileObject);
    },
    onClickAvatarButton() {
      this.$modal.show("avatar-modal");
    },
    onClickAvatarSelectButton(avatar) {
      this.updatePlayerAvatar({
        id: this.player.id,
        avatar: avatar
      }).then(() => {
        this.$modal.hide("avatar-modal");
      })
    },
    onClickAliasButton() {
      this.$modal.show("alias-modal");
    },
    onOpenedAliasModal() {
      this.$refs.alias.focus();
    },
    onClosedAliasModal() {
      this.alias = this.player.alias;
    },
    onClickSaveAliasButton() {
      if (this.alias) {
        this.updatePlayerAlias({
          id: this.player.id,
          alias: this.alias
        }).then(() => {
          this.$modal.hide("alias-modal");
        }).catch(error => {
          this.$toast.error(error.response.data.message);
        })
      }
    },
    onClickUpdatePaymentMethodButton() {
      this.getPaymentMethods().then((payload) => {
        this.paymentMethods = payload;
        this.$modal.show("payment-methods-modal");
      })
    },
    onClickDeletePaymentMethodButton(id) {
      this.$modal.hide("payment-methods-modal");
      this.$modal.show('dialog', {
        title: 'Kayıtlı kredi kartınız silmek istediğinize emin misiniz?',
        class: "modal",
        buttons: [{
          title: 'Hayır',
          class: "btn btn--primary btn--sm mx-6 mb-4 w-1/2",
          handler: () => {
            this.$modal.hide('dialog')
          }
        },
          {
            title: 'Evet',
            class: "btn btn--secondary btn--sm mx-6 mb-4 w-1/2",
            handler: () => {
              this.removePaymentMethod(id).then(() => {
                this.$modal.hide("dialog");
                this.onClickUpdatePaymentMethodButton();
              })
            }
          }
        ]
      })
    },
    verifyAccount() {
      this.$modal.show('otp-verification-modal')
    },
    onChangeNotificationSetting(value) {
      let permission = !value

      if(permission) {
        this.enablePlayerNotification().then(() => {
          this.$toast.success('Bildirim tercihiniz başarıyla güncellendi.');
          this.isNotificationEnabled = true
        })
      }else{
        this.disablePlayerNotification().then(() => {
          this.$toast.success('Bildirim tercihiniz başarıyla güncellendi.');
          this.isNotificationEnabled = false
        })
      }
    },
    getRewards() {
      this.rewardsLoading = true;
      Promise.all([this.getRewardPowerups(), this.getRewardCoupons()]).then((dataArray) => {
        this.rewardsLoading = false;

        let newArray = []
        dataArray[0]?.set.forEach((element)=>{
          let date = element.powerups.rewardedAt
          let rewardDate = new Date(date.substring(6) + '-' + date.substring(3,5) + '-' + date.substring(0,2));
          let startDate = new Date(rewardDate.getFullYear(), 0, 1);
          let days = Math.floor((rewardDate - startDate) / (24 * 60 * 60 * 1000));
          let weekNumber = Math.ceil(days / 7) - 1;
          element.powerups.rewardedAt = weekNumber + '. Hafta'
          newArray.push(element)
        })
        this.rewards = newArray;
        this.coupons = dataArray[1]?.set;
        dataArray[1]?.set.forEach((val) => {
          if(val.giftCard.displayedAt){
            this.openedCoupons.push(val.giftCard.id)
          }
        })
      });
    },
    onChangeEffectSetting(value) {
      let permission = !value

      if(permission) {
        this.enableSoundEffects().then(() => {
          this.$toast.success('Ses efektleri açıldı.');
          this.isSoundEffectsEnabled = true
        })
      }else{
        this.disableSoundEffects().then(() => {
          this.$toast.success('Ses efektleri tercihiniz üzerine kapatıldı.');
          this.isSoundEffectsEnabled = false
        })
      }
    },
    onClickOpenCoupon(couponId){
      this.openedCoupons.push(couponId)
      this.feedAllEvent('click_button', couponId, null, null, 'open_coupon')
    },
    onClickInviteFriend(){
      this.$modal.show('invite-friend-modal')
    },
    copyURL(text) {
      navigator.clipboard.writeText(text);
      this.isLinkCopied = true;
      setTimeout(() => {
        this.isLinkCopied = false;
      }, 1000);
    }
  }
}
</script>