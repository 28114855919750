<template>
  <div>
    <modal 
      name="coupon-help-modal" 
      classes="modal"
      :minWidth="200"
      :minHeight="400"
      :maxWidth="480"
      :scrollable="true"
      :adaptive="true"
      :reset="true"
      width="100%"
      height="auto">
      
      <div class="flex justify-end relative">
        <button @click="$modal.hide('coupon-help-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <div class="flex container px-8 mt-8 flex-col items-center justify-center">
        <h2 class="mb-6 mt-4">Hediye Çekleri Kullanım Kılavuzu</h2>
        <ul class="list-disc ml-6 mb-8">
          <li>
            Alışveriş sonrasında kalan bakiye aynı kod ile tekrar kullanılabilir.
          </li>
          <li>Hediye Kodu altın ve cep telefonu ve dijital ürün kategorilerinde geçerli değildir.</li>
          <li>Hızlı Market & Yemek dahildir.</li>
          <li>Hediye kod tutarı üstüne cüzdana istenilen miktarda para aktarılabilir.</li>
          <li>Hediye kodu iptal ve iade durumunda sipariş tutarı tekrar cüzdana yüklenir.</li>
          <li>Hediye kodlarının kullanım süresi bir yıldır.</li>
          <li>Mevcut Trendyol hesabınız ile giriş yapabilirsiniz. Eğer Trendyol hesabınız yoksa hesap oluşturmanız gerekmektedir.</li>
          <li>Trendyol hesabınıza giriş yaptıktan sonra cüzdan sekmesine tıklayıp, cep telefonu numaranız ile cüzdanınızı aktif etmeniz gerekmektedir.</li>
        </ul>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  methods: {
    show() {
      this.$modal.show("coupon-help-modal");
    },
    hide() {
      this.$modal.hide("coupon-help-modal");
    }
  }
}
</script>